import React from 'react';

import Button from './Button';
import {ButtonType} from '../../../constants';

const ButtonPrimary = (props) => (
  <Button {...props} type={ButtonType.PRIMARY} />
);

export default ButtonPrimary;
