import React from 'react';

import {ButtonLink as CommonButtonLink} from '@core/ui';

import Button from './Button';

const ButtonLink = (props) => (
  <CommonButtonLink component={Button} {...props} />
);

export default ButtonLink;
