import React from 'react';

import {Select as CommonSelect} from '@core/ui';

import {InputFrame} from '../input';
import Menu from '../menu';

const Select = (props) => (
  <CommonSelect frameComponent={InputFrame} menuComponent={Menu} {...props} />
);

export default Select;
