import React from 'react';

import {ButtonLink as CommonButtonLink} from '@core/ui';

import Button from './Button';
import {ButtonType} from '../../../constants';

const ButtonLinkPrimary = (props) => (
  <CommonButtonLink component={Button} type={ButtonType.PRIMARY} {...props} />
);

export default ButtonLinkPrimary;
