import React from 'react';

import {SelectFrame as CommonSelectFrame} from '@core/ui';

import {InputFrame} from '../input';

const SelectFrame = (props) => (
  <CommonSelectFrame {...props} frameComponent={InputFrame} />
);

export default SelectFrame;
