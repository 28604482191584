import React from 'react';
import PropTypes from 'prop-types';

import {Notice as CommonNotice, Icon, Bar} from '@core/ui';

import {IconSize, IconType} from '../../../constants';

const Notice = ({smallIcon, ...props}) => (
  <CommonNotice
    {...props}
    barComponent={Bar}
    iconComponent={Icon}
    iconComponentProps={{
      type: props.icon,
      inherit: true,
      muted: !props.inverse,
      size: smallIcon ? IconSize.SIZE_48 : IconSize.SIZE_96,
      ...(props.iconType ? {[props.iconType]: true} : {}),
    }}
  />
);

Notice.propTypes /* remove-proptypes */ = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  smallIcon: PropTypes.bool,
  inverse: PropTypes.bool,
  iconType: PropTypes.oneOf(Object.values(IconType)),
};

export default Notice;
