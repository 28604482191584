import React from 'react';

import {BaseInput as CommonBaseInput} from '@core/ui';

import InputFrame from './InputFrame';

const BaseInput = (props) => (
  <CommonBaseInput {...props} frameComponent={InputFrame} />
);

export default BaseInput;
