import React from 'react';

import {ClosablePopover as CommonClosablePopover} from '@core/ui';

import {ButtonFlat} from '../button';
import css from './Popover.css';

const ClosablePopover = (props) => (
  <CommonClosablePopover buttonComponent={ButtonFlat} css={css} {...props} />
);

export default ClosablePopover;
