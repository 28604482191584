import React, {forwardRef} from 'react';

import {Menu as CommonMenu} from '@core/ui';

import {Popover} from '../popper';

const Menu = forwardRef((props, ref) => (
  <CommonMenu {...props} ref={ref} popoverComponent={Popover} />
));

Menu.displayName = 'Menu';

export default Menu;
