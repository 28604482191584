import React, {forwardRef} from 'react';

import {Tab as CommonTab} from '@core/ui';

import css from './Tab.css';

const Tab = forwardRef((props, ref) => (
  <CommonTab {...props} ref={ref} css={css} />
));

Tab.displayName = 'Tab';

export default Tab;
