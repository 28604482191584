import React, {forwardRef} from 'react';

import {Input as CommonInput} from '@core/ui';

import BaseInput from './BaseInput';

const Input = forwardRef((props, ref) => (
  <CommonInput {...props} inputComponent={BaseInput} ref={ref} />
));

export default Input;
