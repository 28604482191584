import React from 'react';

import {ErrorMessage, InputFrame as CommonInputFrame} from '@core/ui';

import Label from '../label';
import css from './InputFrame.css';

const InputFrame = (props) => (
  <CommonInputFrame
    {...props}
    labelComponent={Label}
    errorMessage={ErrorMessage}
    css={css}
  />
);

export default InputFrame;
