import React, {forwardRef} from 'react';

import {Progress as BaseProgress} from '@core/ui';

import css from './Progress.css';

const Progress = forwardRef((props, ref) => (
  <BaseProgress {...props} ref={ref} css={css} />
));

export default Progress;
