import React, {forwardRef} from 'react';

import {Separator as CommonSeparator} from '@core/ui';

import css from './Separator.css';

const Separator = forwardRef((props, ref) => (
  <CommonSeparator {...props} ref={ref} css={css} />
));

Separator.displayName = 'Separator';

export default Separator;
