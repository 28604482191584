import React from 'react';

import {Slider as CommonSlider} from '@core/ui';

import Label from '../label';
import css from './Slider.css';

const Slider = (props) => (
  <CommonSlider {...props} labelComponent={Label} css={css} />
);

export default Slider;
