import React from 'react';

import {Textarea as CommonTextarea} from '@core/ui';

import BaseInput from './BaseInput';

const Textarea = (props) => (
  <CommonTextarea {...props} inputComponent={BaseInput} />
);

export default Textarea;
