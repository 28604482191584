import React, {forwardRef} from 'react';

import {Popover as CommonPopover} from '@core/ui';

import css from './Popover.css';

const Popover = forwardRef((props, ref) => (
  <CommonPopover {...props} css={css} ref={ref} />
));

export default Popover;
