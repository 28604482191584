import React from 'react';

import {MultipleSelect as CommonMultipleSelect} from '@core/ui';

import {InputFrame} from '../input';
import Menu from '../menu';
import {Tag} from '../tag';

const MultipleSelect = (props) => (
  <CommonMultipleSelect
    frameComponent={InputFrame}
    menuComponent={Menu}
    tagComponent={Tag}
    {...props}
  />
);

export default MultipleSelect;
